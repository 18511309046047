<template>
  <div class="sponsor-component" :class="classes" @click="select" ref="element">
    <div class="logo">
      <img :src="logo" :alt="name">
    </div>

    <p class="description">
      {{ description }}
    </p>

    <Premium v-if="premium"/>
    <ComingSoon v-if="comingSoon"/>
  </div>
</template>

<script>
import Premium from '@/components/Badges/Premium'
import ComingSoon from '@/components/Badges/ComingSoon'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Sponsor',
  mixins: [
    newcompetition
  ],
  components: {
    Premium,
    ComingSoon
  },
  props: {
    sponsor: {
      type: Object,
      required: true
    }
  },
  computed: {
    classes () {
      const { premium, selected } = this
       
      return (this.sponsor.status !== 'Coming soon') ? { premium, selected } : {premium}
    },
    id () {
      return this.sponsor.id
    },
    name () {
      return this.sponsor.name
    },
    logo () {
      return this.sponsor.logo
    },
    selected () {
      return this.selectedAppearance.sponsors?.id === this.id
    },
    description () {
      return this.sponsor.description
    },
    premium () {
      return this.sponsor.status === 'Premium'
    },
    comingSoon () {
      return this.sponsor.status === 'Coming soon'
    }
  },
  mounted () {
    if (this.selected) {
      this.scroll()
    }
  },
  methods: {
    select () {
      if (this.sponsor.status !== 'Coming soon') {
        this.updateAppearance('sponsors', this.sponsor)
  
        this.scroll()
      }
    },
    scroll () {
      this.$refs.element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
}
</script>
