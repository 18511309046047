<template>
  <subview class="newcompetition-appearence-sponsors">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>

            {{ $t('pages.newcompetition.appearance.back') }}
          </div>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <h4> {{ title }} </h4>

      <Sponsor v-for="sponsor in sponsors" :key="sponsor.id" :sponsor="sponsor"/>
    </view-content>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import Sponsor from '@/components/NewCompetition/Appearance/Sponsor'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Sponsors',
  mixins: [
    newcompetition
  ],
  components: {
    Icon,
    Sponsor
  },
  computed: {
    title () {
      return this.selectedSport?.appearance.sponsors.title || this.$t('pages.newcompetition.appearance.sponsors.title')
    },
    sponsors () {
      return this.selectedSport?.appearance.sponsors.variations || []
    }
  }
}
</script>
